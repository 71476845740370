import { useEffect } from 'react';
import Link from './link';

function Header() {

  useEffect(() => {
    document.title = window.Config.SiteName;
  }, []);

  return (
    <div id='header'>
      <div className='container'>
        <div className='logo'>
          <span class="logo-wrapper" data-v-3bcb04ac="">
            <img class="logo me-2" src="https://ll.sc.cn/img/avatar.png"></img>
          </span>
          <h2 className='title'>{window.Config.SiteName}</h2>
        </div>
        <div className='navi'>
          {window.Config.Navi.map((item, index) => (
            <Link key={index} to={item.url} text={item.text} />
          ))}
        </div>
      </div>
      <div className='status-top'>
        <div className='status-tip' id='status-tip'></div>
        <p className='status-text' id='status-text'>站点状态加载中</p>
        <p className='status-text' id='status-down'>部分站点无法运行</p>
        <p className='status-time' id='status-time-up'>上次更新于&nbsp;<span id='status-last-time'>00&nbsp;:&nbsp;00</span>&emsp;|&emsp;检测频率&nbsp;30&nbsp;分钟</p>
      </div>
    </div>
  );
}

export default Header;
